<template>
  <div class="height-100">
    <v-main class="height-100">
      <v-container fluid class="height-100 pt-0">
        <v-row class="pl-lg-4 ml-md-4">
          <v-col cols="12">
            <v-toolbar flat>
              <v-icon size="90">$vuetify.icons.osg-logo</v-icon>
              <v-divider class="mx-6 grey lighten-1" vertical></v-divider>
              <span class="primary--text text-subtitle-1">{{ $t('login.appTitle') }}</span>
            </v-toolbar>
          </v-col>
        </v-row>
        <v-row v-if="!isEmailSent" class="height-75" align="center" justify="center">
          <v-col cols="12">
            <v-row align="center" justify="center">
              <v-col class="d-flex justify-center py-0">
                <v-alert v-model="alert" border="left" max-width="560" elevation="5" colored-border dense type="error">
                  {{ message }}
                </v-alert>
              </v-col>
            </v-row>
            <v-row align="center" justify="center">
              <v-col cols="12" sm="8" md="4" lg="4" xl="3">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <label class="text-h3 secondary--text mt-5">{{ $t('forgotPassword.title') }}</label>
                  </v-col>
                </v-row>        
                <v-row>
                  <v-col cols="12" class="pb-4 pt-0">
                    <label class="text-body-1">{{ $t('forgotPassword.instructions') }}</label>
                  </v-col>
                </v-row>       
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-form ref="forgotPasswordForm"
                      v-model="valid"
                      lazy-validation>
                      <v-row >
                        <v-col cols="12">
                          <v-text-field
                            v-model="email"
                            id="email"
                            :label="$t('forgotPassword.email.inputLabel')"
                            :rules="[rules.required, rules.email]"
                            name="email"
                            type="text"
                            class="rounded"
                            dense
                            outlined
                            hide-details="auto"
                            required
                          >
                          </v-text-field>
                        </v-col>
                      </v-row>
                    </v-form>
                  </v-col>
                  <v-col cols="12" class="text-right">
                    <v-btn 
                      class="secondary--text text-button mr-3 rounded"
                      depressed
                      color="grey lighten-5"
                      min-width="71px"
                      @click="returnToLogin()">
                      {{ $t('forgotPassword.returnLogindButton') }}
                    </v-btn>
                    <v-btn
                      color="primary"
                      depressed
                      min-width="71px"
                      class="text-button rounded"
                      @click="validate()">
                      {{ $t('forgotPassword.recoverPasswordButton') }}</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else class="height-75" align="center" justify="center">
          <v-col cols="12" sm="8" md="4" lg="4" xl="3">
            <MessageTemplate icon-name="osg-email"
                  :title="message">
              <v-row slot="actions" class="mt-2">
                <v-col class="border-top text-center pt-5">
                  <v-btn 
                  depressed
                  min-width="108px"
                  class="button-secondary rounded"
                  @click="tryAgain()" 
                  >
                  {{ $t('forgotPassword.tryAgainButton') }}</v-btn>
                </v-col>
              </v-row>
            </MessageTemplate>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <OsiguFooter />  
  </div>
</template>

<script>
import { inputRules } from '@/utils';
import OsiguFooter from '../footer/OsiguFooter.vue';
import MessageTemplate from '@/components/message/MessageTemplate';
import { createNamespacedHelpers } from 'vuex'
const authModule = createNamespacedHelpers('auth');

export default {
  props: {
    source: String,
    token: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      valid: true,
      email: '',
      alert: false,
      message: '',
      isEmailSent: false,
      rules: inputRules
    }
  },
  components: {
    OsiguFooter,
    MessageTemplate
  },
  mounted() {
    if (this.usedEmail) {
      this.email = this.usedEmail;
    }
  },
  computed: {
    ...authModule.mapGetters(['usedEmail'])
  },
  methods: {
    ...authModule.mapActions(['requestClientToken', 'requestForgotPassword']),
    returnToLogin: function () {
      this.alert = false;
      this.$router.push('/login')
    },
    recoveryPassword: function () {
      this.alert = false;
      this.message = this.$i18n.t('globalErrorUnknown')
      this.requestClientToken()
      .then(() => {
        return this.requestForgotPassword({ email: this.email })
      })
      .then(() => {
        this.message = this.$i18n.t('forgotPassword.success.label')
        this.isEmailSent = true
      })
      .catch((error) => {
        const response = error.response
        if (response) {
          const status = response.status
          if (status === 429) {
            this.message = response.data.message
          }
        }
        this.alert = true;
      })
    },
    validate: function () {
      if (this.$refs.forgotPasswordForm.validate()) {
        this.recoveryPassword()
      }
    },
    tryAgain: function () {
      this.alert = false;
      this.isEmailSent = false
    }
  }
}
</script>

<style lang="scss" scoped>
.height-100 {
  height: 100%;
}

.height-75 {
  height: 75%;
}

.border-top {
  border-top-style: solid;
  border-top-width: 1px;
  border-color: #e0e0e0;
  font-family: 'Open Sans';
  font-weight: 800;
}
</style>